<template>
  <BaseTableWrapper :table-init="tableInit">
    <h1 class="page-header">Chats</h1>
  </BaseTableWrapper>
</template>

<script>
import { DateTime } from 'luxon'
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import BaseActionsRequiresWrapperVue from '@/components/BaseActionsRequiresWrapper.vue'
import QuotesActionableColumn from '@/components/QuotesActionableColumn.vue'
import chats from '@/services/chats'
import { authComputed } from '@/state/helpers'
import { deepClone } from '@/utils/deepClone'
import { noFutureDatesPredefined } from '@/utils/predefined'
import actionsWrapper from '@/utils/BaseActionsWrapper'

const chatStatusMap = [
  { text: 'Completed', value: 'completed' },
  { text: 'In Progress', value: 'inprogress' },
  { text: 'Cancelled', value: 'cancelled' },
]
const chatOutcomeMap = [
  { text: 'Booked', value: 'booked' },
  { text: 'Interested', value: 'interested' },
  { text: 'Not Interested', value: 'not_interested' },
  { text: 'Disconnected', value: 'disconnected' },
]

const categories = [
  {
    _t_id: 'cfb42fbe',
    text: 'Chat Status',
    prop: 'chatStatus/key',
    values: deepClone(chatStatusMap),
    type: 'text',
    method: 'or',
  },
  {
    _t_id: 'c2883884',
    text: 'Chat Outcomes',
    prop: 'chatOutcome/key',
    values: deepClone(chatOutcomeMap),
    type: 'text',
    method: 'or',
  },
]
const actions = ['view-chat-sidebar']

export default {
  components: {
    BaseTableWrapper,
  },
  metaInfo() {
    return {
      title: 'Chats',
    }
  },
  props: {
    mode: {
      type: String,
      default: '',
    },
  },
  data() {
    const columns = [
      {
        _t_id: 'f2ca7cb3',
        prop: '/',
        text: 'Actions',
        component: actionsWrapper(
          BaseActionsRequiresWrapperVue,
          'chat',
          actions
        ),
        sort: false,
        filter: false,
        detail: false,
        type: 'actions',
      },
      {
        _t_id: 'z9c6c7fc1',
        prop: 'chatId',
        text: 'Chat Id',
        filter: true,
        sort: true,
        type: 'text',
        filterType: 'eq',
        defaultSort: true,
      },
      {
        _t_id: 'c9c6c7fc1',
        prop: 'chatFrom',
        text: 'Customer Name',
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'contains',
      },
      {
        _t_id: 'b59b33f1a',
        prop: 'email',
        text: 'Email',
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'contains',
      },
      {
        _t_id: '4e8db1f20',
        prop: 'phoneNumber',
        text: 'Phone',
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'contains',
      },
      {
        _t_id: '464151b8',
        prop: 'answeredByName',
        text: 'Answered By',
        sort: true,
        type: 'text',
        filter: true,
        filterType: 'contains',
      },
      {
        _t_id: 'a0f28021',
        prop: 'chatStartTime',
        text: 'Chat Start Time',
        sort: true,
        sortProp: 'entryDate',
        filter: true,
        type: 'date',
        method: 'and',
        childMethod: 'and',
        filterType: 'eq',
        predefined: deepClone(noFutureDatesPredefined),
        forceOneLine: true,
        computedText: (item) =>
          DateTime.fromISO(item).toFormat('MM/dd/yyyy hh:mm a'),
      },
      {
        _t_id: 'a0f28023',
        prop: 'chatAcceptTime',
        text: 'Chat Accept Time',
        sort: true,
        sortProp: 'entryDate',
        filter: true,
        type: 'date',
        method: 'and',
        childMethod: 'and',
        filterType: 'eq',
        predefined: deepClone(noFutureDatesPredefined),
        forceOneLine: true,
        computedText: (item) =>
          DateTime.fromISO(item).toFormat('MM/dd/yyyy hh:mm a'),
      },
      {
        _t_id: 'a0f28022',
        prop: 'chatEndTime',
        text: 'Chat End Time',
        sort: true,
        sortProp: 'entryDate',
        filter: true,
        type: 'date',
        method: 'and',
        childMethod: 'and',
        filterType: 'eq',
        predefined: deepClone(noFutureDatesPredefined),
        forceOneLine: true,
        computedText: (item, row) => {
          if (row.chatStatus.key === 'inprogress' && row.chatAcceptTime) {
            return ''
          }
          return DateTime.fromISO(item).toFormat('MM/dd/yyyy hh:mm a')
        },
      },
      {
        _t_id: 'dcb792d8',
        prop: 'chatStatus/label',
        text: 'Chat Status',
        sort: true,
        type: 'text',
        filter: true,
        filterType: 'contains',
      },
      {
        _t_id: 'dcb792d9',
        prop: 'chatReason/label',
        text: 'Chat Reason',
        sort: true,
        type: 'text',
        filter: true,
        filterType: 'contains',
      },
      {
        _t_id: 'dcb792d2',
        prop: 'chatOutcome/label',
        text: 'Chat Outcome',
        sort: true,
        type: 'text',
        filter: true,
        filterType: 'contains',
      },
      {
        _t_id: '3c5b7f92',
        prop: 'quoteId',
        component: QuotesActionableColumn,
        text: 'Quote ID',
        sort: true,
        type: 'number',
        filter: true,
        filterType: 'eq',
        displayType: 'clickable-link-new',
      },
    ]

    return {
      selectedRows: [],
      isAdmin: false,
      tableInit: {
        enableExport: false,
        enableExportOverride: false,
        enableColumnConfig: false,
        enableSavedViews: false,
        enableStatusFilterToggle: false,
        tableId: 'chats_table_view',
        currentPage: 1,
        perPage: 10,
        collection: 'Chats',
        categories: categories,
        columns: columns,
        action: (params) => chats.getChats(params),
      },
    }
  },
  computed: {
    ...authComputed,
  },
}
</script>
